<template>
  <div class="component-wrap">
    <v-container grid-list-md>
      <v-flex pt-3 pb-4>
        <h1 style="color:#0000008a;" class="text-md-center">
          <img src="media/imgs/logo.png" />
        </h1>
      </v-flex>
      <!-- <KTQuickUser></KTQuickUser> -->
      <v-container grid-list-lg>
        <v-layout wrap>
          <v-flex xs12 md6 v-if="role == 'Admin'">
            <v-hover
              v-slot:default="{ hover }"
              open-delay="100"
              close-delay="100"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto text-center"
                max-width="300"
                @click="
                  $router.push({
                    name: 'all-tasks',
                    params: { type: 'general-tasks' }
                  })
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div style="color:gray;font-size:20px;">
                      عدد المهام الكلي
                    </div>
                    <v-list-item-title
                      style="color:gray;font-size:15px;"
                      class="headline mb-1"
                      >{{ totalTasks }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 md6 v-if="role == 'Admin'">
            <v-hover
              v-slot:default="{ hover }"
              open-delay="100"
              close-delay="100"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto text-center"
                max-width="300"
                @click="$router.push('/visits')"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div style="color:gray;font-size:20px;">
                      عدد الزيارات الكلي
                    </div>
                    <v-list-item-title
                      style="color:gray;font-size:15px;"
                      class="headline mb-1"
                      >{{ totalVisits }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 md4>
            <v-hover
              v-slot:default="{ hover }"
              open-delay="100"
              close-delay="100"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto text-center"
                max-width="300"
                @click="
                  $router.push({
                    name: 'all-tasks',
                    params: { type: 'new-tasks' }
                  })
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div style="color:gray;font-size:20px;">
                      عدد المهام المعينة
                    </div>
                    <v-list-item-title
                      style="color:gray;font-size:15px;"
                      class="headline mb-1"
                      >{{ NewAssignedTasks }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 md4>
            <v-hover
              v-slot:default="{ hover }"
              open-delay="100"
              close-delay="100"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto text-center"
                max-width="300"
                @click="
                  $router.push({
                    name: 'all-visit',
                    params: { type: 'new-visits' }
                  })
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div style="color:gray;font-size:20px;">
                      الزيارات المعنية
                    </div>
                    <v-list-item-title
                      style="color:gray;font-size:15px;"
                      class="headline mb-1"
                      >{{ NewTasksCount }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-flex>
          <v-flex xs12 md4>
            <v-hover
              v-slot:default="{ hover }"
              open-delay="100"
              close-delay="100"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto text-center"
                max-width="300"
                @click="
                  $router.push({
                    name: 'all-tasks',
                    params: { type: 'doing-task-tasks' }
                  })
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div style="color:gray;font-size:20px;">
                      المهام قيد التقدم
                    </div>
                    <v-list-item-title
                      style="color:gray;font-size:15px;"
                      class="headline mb-1"
                      >{{ DoingTask }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-container>
      <v-layout row-wrap mt-5 mb-2 v-if="role == 'Salesman'">
        <v-flex>
          <v-card elevation="4" xs12 sm4 md4>
            <v-card-title>
              <span class="headline" style="color:gray;font-size:15px;">
                <v-icon>receipt</v-icon>
                أخر الزيارات
              </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container grid-list-lg>
                <v-flex xs12 sm12 md12>
                  <v-simple-table loading fixed-header width="900">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center"><b>اسم المهمة</b></th>
                          <!--    <th class="text-center"><b>حالة الزيارة</b></th>-->
                          <th class="text-center"><b>نوع الجهة الخارجية</b></th>
                          <th class="text-center"><b>الجهة الخارجية</b></th>
                          <th class="text-center"><b>تاريخ البداية</b></th>
                          <th class="text-center"><b>تاريخ النهاية</b></th>
                        </tr>
                      </thead>
                      <tbody v-if="visits.length > 0">
                        <tr
                          @click="
                            $router.push({
                              name: 'show-visit',
                              params: { visitId: item.idvisit, visit: item }
                            })
                          "
                          v-for="item in visits"
                          :key="item.idvisit"
                        >
                          <td class="text-center">{{ item.task.name }}</td>
                          <td class="text-center">
                            {{
                              item.external
                                ? item.external.cementPackage == 1
                                  ? "مصنع"
                                  : item.external.cementPackage == 3
                                  ? "مقاول"
                                  : item.external.cementPackage == 4
                                  ? "مبسط"
                                  : item.external.cementPackage == 2
                                  ? "مشروع"
                                  : item.external.cementPackage == 5
                                  ? "موقف"
                                  : "ناقل"
                                : ""
                            }}
                          </td>
                          <td class="text-center">
                            {{ item.external ? item.external.name : "" }}
                          </td>
                          <!--     <td class="text-center">{{
                                    item.task.statusId==3?'منجزة':item.task.statusId==4?'جديدة':item.task.statusId==5?'بانتظار المراجعة':item.task.statusId==2?'قيد التقدم':''
                                    }}</td>-->
                          <td class="text-center">
                            {{ createdDate(item.task.startDate) }}
                          </td>
                          <td class="text-center">
                            {{ createdDate(item.task.endDate) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td
                            colspan="5"
                            class="text-center"
                            style="color:gray;font-size:12px;"
                          >
                            لا توجد بيانات لعرضها
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row-wrap mt-5 mb-2 v-if="role == 'Salesman' && showMap">
        <v-flex>
          <gmap-map
            :center="center"
            :zoom="8"
            style="width:100%;  height: 400px;"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m"
              @click="center = m"
            ></gmap-marker>
          </gmap-map>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
// import { GET_USER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
// import KTHeader from "@/view/layout/header/Header.vue";
// import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
export default {
  data() {
    return {
      showMap: false,
      NewTasksCount: 0,
      center: { lat: 24.4, lng: 46.71667 },
      markers: [], //[{lat:35.52068 , lng:35.79966, title: "Hello World!",}],
      currentPlace: null,
      totalVisits: 0,
      visits: [],
      role: "",
      rolee: "",
      totalTasks: 0,
      NewAssignedTasks: 0, // موكلة
      DoingTask: 0 // قيد التقدم
    };
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.rolee = JSON.stringify(window.localStorage.getItem("id"));
    // this.$router.push({ name: "layout" });

    //    this.role = JSON.stringify(window.localStorage.getItem("roles"));
    //   // this.getrol()
    //  this.$store.dispatch(GET_USER).then(() => {
    //     this.role = window.localStorage.getItem("roles");
    // });
    this.getuserid();

    if (this.roles == "Salesman") {
      this.getLastVisits();
      this.getNewVisits();
    }
    this.getAllTask();
    this.getNewAssignedTasks();
    this.getDoingTask();
    this.getAllVisits();
    this.getNewVisitsCountTasks();
  },
  mounted() {
    this.geolocate();
    this.roles = localStorage.getItem("roles");
  },
  methods: {
    getuserid() {
      ApiService.get("Auth/GetUser?id=" + this.user.id).then(data => {
        this.role = data.data.roles[0];
      });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
      this.markers = [];
    },
    getNewVisits() {
      this.ApiService.get("visit/NewVisitsByUser?user=" + this.user.id, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token")
        }
      })
        .then(res => {
          res.data.responseData.forEach(el => {
            if (el != null && el.external.lat != null) {
              this.showMap = true;
              this.markers.push({
                lat: el.external.lat,
                lng: el.external.lng,
                label: el.external.location
              });
            }
          });
        })
        .catch(() => {});
    },
    async getAllTask() {
      await this.ApiService.get("task/GetAllTasks", {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token")
        }
      })
        .then(res => {
          let data = res.data.responseData;
          data.forEach(el => {
            if (el.typeId != 1) this.totalTasks++;
          });
        })
        .catch(() => {});
    },
    async getNewAssignedTasks() {
      let url = null;
      if (this.roles == "Admin") {
        url = "task/NewAssignedTasks";
      } else {
        url = "task/NewTasksByUser?user=" + this.user.id;
      }
      await this.ApiService.get(url, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token")
        }
      })
        .then(res => {
          this.NewAssignedTasks = res.data.responseData.length;
        })
        .catch(() => {});
    },
    async getDoingTask() {
      let url = null;
      if (this.roles == "Admin") {
        url = "task/DoingTask";
      } else {
        url = "task/DoingTasksByUser?user=" + this.user.id;
      }
      await this.ApiService.get(url)
        .then(res => {
          this.DoingTask = res.data.responseData.length;
        })
        .catch(() => {});
    },
    async getAllVisits() {
      await this.ApiService.get(
        "Visit/GetAllVisitsByCreatorRole?user=" + this.user.id,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          let k = 0;
          res.data.responseData.forEach(el => {
            if (el != null) {
              k++;
            }
          });
          this.totalVisits = k;
        })
        .catch(() => {});
    },
    async getNewVisitsCountTasks() {
      await this.ApiService.get("visit/NewVisitsByUser?user=" + this.user.id, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token")
        }
      })
        .then(res => {
          this.NewTasksCount = res.data.responseData.length;
        })
        .catch(() => {});
    },
    async getLastVisits() {
      await this.ApiService.get(
        "Visit/ArchiveVisitsByUser?user=" + this.user.id,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token")
          }
        }
      )
        .then(res => {
          this.visits = [];
          let AllVisits = [];
          AllVisits = res.data.responseData;
          let k = 1;
          AllVisits.forEach(el => {
            if (el != null) {
              this.visits.push(el);
              k++;
            }
            if (k > 5) return;
          });
        })
        .catch(() => {});
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    }
  }
};
</script>
<style scoped></style>
