var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-flex',{attrs:{"pt-3":"","pb-4":""}},[_c('h1',{staticClass:"text-md-center",staticStyle:{"color":"#0000008a"}},[_c('img',{attrs:{"src":"media/imgs/logo.png"}})])]),_c('v-container',{attrs:{"grid-list-lg":""}},[_c('v-layout',{attrs:{"wrap":""}},[(_vm.role == 'Admin')?_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 16 : 2,"max-width":"300"},on:{"click":function($event){return _vm.$router.push({
                    name: 'all-tasks',
                    params: { type: 'general-tasks' }
                  })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"gray","font-size":"20px"}},[_vm._v(" عدد المهام الكلي ")]),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"color":"gray","font-size":"15px"}},[_vm._v(_vm._s(_vm.totalTasks))])],1)],1)],1)]}}],null,false,2166956032)})],1):_vm._e(),(_vm.role == 'Admin')?_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 16 : 2,"max-width":"300"},on:{"click":function($event){return _vm.$router.push('/visits')}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"gray","font-size":"20px"}},[_vm._v(" عدد الزيارات الكلي ")]),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"color":"gray","font-size":"15px"}},[_vm._v(_vm._s(_vm.totalVisits))])],1)],1)],1)]}}],null,false,1308175482)})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 16 : 2,"max-width":"300"},on:{"click":function($event){return _vm.$router.push({
                    name: 'all-tasks',
                    params: { type: 'new-tasks' }
                  })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"gray","font-size":"20px"}},[_vm._v(" عدد المهام المعينة ")]),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"color":"gray","font-size":"15px"}},[_vm._v(_vm._s(_vm.NewAssignedTasks))])],1)],1)],1)]}}])})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 16 : 2,"max-width":"300"},on:{"click":function($event){return _vm.$router.push({
                    name: 'all-visit',
                    params: { type: 'new-visits' }
                  })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"gray","font-size":"20px"}},[_vm._v(" الزيارات المعنية ")]),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"color":"gray","font-size":"15px"}},[_vm._v(_vm._s(_vm.NewTasksCount))])],1)],1)],1)]}}])})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-hover',{attrs:{"open-delay":"100","close-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto text-center",attrs:{"elevation":hover ? 16 : 2,"max-width":"300"},on:{"click":function($event){return _vm.$router.push({
                    name: 'all-tasks',
                    params: { type: 'doing-task-tasks' }
                  })}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"gray","font-size":"20px"}},[_vm._v(" المهام قيد التقدم ")]),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"color":"gray","font-size":"15px"}},[_vm._v(_vm._s(_vm.DoingTask))])],1)],1)],1)]}}])})],1)],1)],1),(_vm.role == 'Salesman')?_c('v-layout',{attrs:{"row-wrap":"","mt-5":"","mb-2":""}},[_c('v-flex',[_c('v-card',{attrs:{"elevation":"4","xs12":"","sm4":"","md4":""}},[_c('v-card-title',[_c('span',{staticClass:"headline",staticStyle:{"color":"gray","font-size":"15px"}},[_c('v-icon',[_vm._v("receipt")]),_vm._v(" أخر الزيارات ")],1)]),_c('v-divider'),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-lg":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-simple-table',{attrs:{"loading":"","fixed-header":"","width":"900"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("اسم المهمة")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("نوع الجهة الخارجية")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("الجهة الخارجية")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("تاريخ البداية")])]),_c('th',{staticClass:"text-center"},[_c('b',[_vm._v("تاريخ النهاية")])])])]),(_vm.visits.length > 0)?_c('tbody',_vm._l((_vm.visits),function(item){return _c('tr',{key:item.idvisit,on:{"click":function($event){return _vm.$router.push({
                              name: 'show-visit',
                              params: { visitId: item.idvisit, visit: item }
                            })}}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.task.name))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.external ? item.external.cementPackage == 1 ? "مصنع" : item.external.cementPackage == 3 ? "مقاول" : item.external.cementPackage == 4 ? "مبسط" : item.external.cementPackage == 2 ? "مشروع" : item.external.cementPackage == 5 ? "موقف" : "ناقل" : "")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.external ? item.external.name : "")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.task.startDate))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.createdDate(item.task.endDate))+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"color":"gray","font-size":"12px"},attrs:{"colspan":"5"}},[_vm._v(" لا توجد بيانات لعرضها ")])])])]},proxy:true}],null,false,3272623424)})],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.role == 'Salesman' && _vm.showMap)?_c('v-layout',{attrs:{"row-wrap":"","mt-5":"","mb-2":""}},[_c('v-flex',[_c('gmap-map',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":8}},_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m},on:{"click":function($event){_vm.center = m}}})}),1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }